<template>
  <div class="user-agreement-wrap">
    <div class="logo">
      <img src="../assets/images/bentley_logo_a-c1@2x.png"  alt="" />
    </div>
    <div class="title">賓利經銷商員工培訓線上報名系統<br>個人信息保護聲明</div>
    <div class="content">{{ userAgreement }}</div>
    <div class="btn-groups" @click="closeDialog">確定</div>
  </div>
</template>
<script>
import {userAgreement} from './definitions/user-agreement'

export default {
  name: 'UserAgreement',
  data() {
    return {
      userAgreement,
      hubId: this.$route.params.hubId || null,
      password: this.$route.params.password || null,
      phone: this.$route.params.phone || null,
      verificationCode: this.$route.params.verificationCode || null,
      declare: this.$route.params.declare || null,
    }
  },
  methods: {
    closeDialog() {
      this.$router.push({name: 'Login', params: {phone: this.phone, verificationCode: this.verificationCode, hubId: this.hubId, password: this.password,  declare: this.declare}})
    },
  }
}
</script>
<style lang="scss" scoped>
.logo {
    width: 375px;
    height: 80px;
    background-color: white;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    img {
        width: 84px;
        height: 42px;
        margin: 20px 20px;
    }
}
.user-agreement-wrap {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  background: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .title {
    text-align: center;
    width: auto;
    height: 20px;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    margin: 20px 0;
  }
  .content {
    width: 100%;
    height: calc(100vh-104px);
    min-height: 100%;
    box-sizing: border-box;
    padding: 0 20px 20px;
    // margin-bottom: 35px;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    line-height: 18px;
    overflow-y: auto;
    overflow-x: hidden;
    white-space: pre-wrap;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }
  // .btn-groups {
  //   width: 100%;
  //   height: 44px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   box-sizing: border-box;
  //   border-top: 1px solid #e0e0e0;
  //   font-size: 18px;
  //   // font-family: PingFang SC;
  //   font-weight: 400;
  //   color: #999999;
  //   span {
  //     width: 100%;
  //     height: 100%;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     &:last-child {
  //       border-left: 1px solid #e0e0e0;
  //       color: rgba(0,50,32,0.7);
  //     }
  //   }
  // }
.btn-groups {
  margin-top:calc(100vh-115px);
    width: 100%;
    height: 35px;
    display: flex;
    // margin: 10px 0px;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,50,32,0.7);
    // border-radius: 4px;
    font-size: 16px;
    color: #fff;
    // position: absolute;
    position: fixed;
    bottom: 0;
    left: 0;
    clear:both;
    
}
}
</style>

export const userAgreement =
`
大眾汽車（中國）銷售有限公司
賓利經銷商員工培訓線上報名系統
個人信息保護聲明
更新日期：2021.06

大眾汽車（中國）銷售有限公司（以下簡稱「VGIC」）是在中華人民共和國登記的全國性銷售公司，住所為天津自貿試驗區（天津港保稅區）天保大道188號開利大廈316室。VGIC擁有賓利汽車有限公司（Bentley Motors Limited）產品進口的獨家授權。賓利汽車中國是VGIC所屬部門。
賓利經銷商員工培訓線上報名系統(後稱「系統」)用於賓利授權經銷商人員進行線上報名註冊及線上課程查詢。VGIC委託迪迪艾咨詢（北京）有限公司（以下簡稱「VGIC供應商」）在系統中收集和處理賓利經銷商員工（以下簡稱「經銷商員工」或者「您」）的個人信息。本聲明適用於該系統中的經銷商員工個人信息的使用、存儲、共享、轉讓以及其它方式的信息處理行為。
本聲明所述的個人信息是指以電子或者其他方式記錄的能夠單獨或與其他信息結合識別特定自然人身份的各種信息。
請仔細閱讀以下條款，當您使用本系統時，應以同意本聲明為前提。如果我們的經銷商員工對本聲明有任何問題或疑慮，或是想對我們可能違反相關法律法規的行為進行投訴，請聯繫賓利經銷商培訓學院010 6531 3249，或者發送電子郵件到bentleyacademy@bentleymotorschina.com 。

本《大眾汽車（中國）銷售有限公司經銷商員工培訓線上報名系統個人信息隱私聲明》（以下簡稱「聲明」）將為我們的經銷商員工提供以下信息：
1.我們如何收集並使用您的個人信息
2.我們如何保護您的個人信息
3.您的權利
4.我們如何處理兒童的個人信息
5.您的個人信息如何在全球轉移
6.本聲明如何更新
7.如何聯繫我們

負責任地處理個人信息是VGIC社會責任和法律責任的一部分，我們深知個人信息對經銷商員工的重要性，並會盡力保護經銷商員工的個人信息安全可靠。該聲明是為了保證經銷商員工的個人信息在中華人民共和國的網絡安全法和其他適用法律法規下能夠得到充分保護。我們將遵循適用法律法規和內部政策所規定的公平合法、明確用途、比例適當、公開透明、選擇同意、確保安全等原則來保護我們經銷商員工的個人信息。我們將採取適當的技術和組織安全措施來保護個人數據免受意外或非法破壞、意外丟失、更改、未經授權的披露或訪問。
我們強烈建議您閱讀以下說明，如有任何問題，請與我們聯繫（電話：010 6531 3249）。

1.我們如何收集並使用您的個人信息

1.1.我們收集哪些您的個人信息
在我們經銷商員工同意的前提下，為了下列用途，VGIC可能會分別收集以下類型的個人信息：
•以經銷商人才發展及培訓活動為目的，我們可能收集您的姓名、稱謂、電話號碼、所屬經銷商、崗位信息、電郵地址、入職信息、工作經驗信息課程學習記錄等。
我們嚴格依據相關的法律法規來收集經銷商員工的個人信息。
在經銷商員工同意的前提下，我們可能通過以下渠道收集經銷商員工的個人信息：
•通過賓利經銷商培訓線上報名系統，我們可能收集經銷商員工的個人信息，收集的個人信息的類型僅限於在本聲明中列出的類型，並且將僅用於本聲明中列出的目的。

1.2.我們如何使用您的個人信息
經銷商員工個人信息的用途如下：
•經銷商人才發展及培訓活動：經銷商崗位人員參與培訓報名，安排經銷商人員進行認證及培訓計劃，查看自身學習培訓進度等。

1.3.我們如何存儲您的個人信息
我們非常認真地對待我們經銷商員工的個人信息。我們經銷商員工的個人信息存儲在VGIC供應商的數據庫里，這些數據庫儲存在採用了嚴格的物理安全措施和IT安全措施保護的設備中，訪問權限很高。我們只有在為實現信息用途所必要的範圍內才會將收集的個人信息保存在我們的數據庫中。
通常情況下，我們對您個人信息的儲存及處理僅存在於您參與培訓及認證計劃期間。若您退出培訓及認證計劃，或結束在品牌的任職工作，在此期限後，我們將會永久刪除您的個人信息。

1.4.我們如何分享、轉讓、公開披露您的個人信息
1.4.1我們如何分享、轉讓您的個人信息
僅在下列情況下，我們才可能會與其他相關實體或個人共享、轉讓或披露經銷商員工的個人信息（您的姓名、稱謂、電話號碼、所屬經銷商、崗位信息、電郵地址、入職信息、工作經驗信息課程學習記錄等）：
•經經銷商員工明確同意，我們可以共享、轉讓或披露經銷商員工的個人信息。
•我們可以根據法律、法規以及行政或司法機關的執法要求或其它強制性要求共享、轉讓或披露經銷商員工的個人信息。
•在涉及合併、收購或破產清算時，如涉及到個人信息轉讓，我們會在要求新的持有您個人信息的公司、組織繼續受此聲明的約束，否則我們將要求該公司、組織重新向您徵求授權同意。
•VGIC供應商必須嚴格遵守本聲明，並採取必要措施保證經銷商員工個人信息的機密性和安全性。我們絕不允許第三方為了未經授權的目的對經銷商員工的個人信息進行共享、轉讓或披露。
1.4.2我們如何公開披露您的個人信息
我們僅會在以下情形下，公開披露您的個人信息：
• 獲得您明確同意後；
• 在法律、法律程序、訴訟或政府主管部門強制性要求的情況下，我們可能會公開披露您的個人信息。

2.我們如何保護您的個人信息
VGIC供應商採用符合公認行業標準的技術和組織安全措施來保護我們所掌握的個人信息不受意外或故意操控、損害、破壞以及未授權人員的訪問。其安全措施包括訪問控制、系統強化、網絡漏洞掃描和管理、意識培訓、日誌和安全事件管理、傳輸和存儲加密等方面，這些安全措施將根據我們的業務需求、科技的進步和監管要求不斷進行必要的更新。
若發生個人信息安全事件，我們將按照內部設計的應急預案進行調查，並採取一切必要措施消除潛在風險，並依據相關法律法規告知經銷商員工。同時，我們還將按照監管部門要求，主動上報個人信息安全事件的處置情況。

3.經銷商員工權利
我們的經銷商員工有權利通過線上和線下的聯繫渠道來訪問、糾正、刪除他們的個人信息，更改他們的授權範圍或者關閉他們的賬戶。請聯繫賓利經銷商培訓學院010 6531 3249，或者發送電子郵件至： bentleyacademy@bentleymotorschina.com。
為保障安全，您可能需要提供書面請求，或以其他方式證明您的身份。我們可能會先要求您驗證自己的身份，然後再處理您的請求。
我們將在三十天內作出答復。如果經銷商員工對收到的答復仍不滿意，可以向有關主管部門提出經銷商員工投訴。如果經銷商員工提出疑問，我們將根據對應的情況，盡力向經銷商員工提供相關部門的信息。
對於您合理的請求，我們原則上不收取費用，但對多次重復、超出合理限度的請求，我們將視情收取一定成本費用。對於那些無端重復、需要過多技術手段（例如，需要開發新系統或從根本上改變現行慣例）、給他人合法權益帶來風險或者非常不切實際（例如，涉及備份磁帶上存放的信息）的請求，我們可能會予以拒絕。
在以下情形中，我們將無法響應您的請求：
a)與個人信息控制者履行法律法規規定的義務相關的；
b)與國家安全、國防安全直接相關的；
c)與公共安全、公共衛生、重大公共利益直接相關的；
d)與刑事偵查、起訴、審判和執行判決等直接相關的；
e)個人信息控制者有充分證據表明個人信息主體存在主觀惡意或濫用權利的；
f)出於維護個人信息主體或其他個人的生命、財產等重大合法權益但又很難得到本人同意的；
g)響應個人信息主體的請求將導致個人信息主體或其他個人、組織的合法權益受到嚴重損害的；
h)涉及商業秘密的。

4.我們如何處理兒童的個人信息
經銷商員工線上培訓報名系統不收集任何兒童信息。 

5.您的個人信息如何在全球範圍轉移
經銷商員工線上培訓報名系統中所收集的個人數據在中國境內進行存儲，我們不會將您的個人數據進行跨境轉移。

6.本聲明如何更新
我們可能會更新本聲明。我們在發佈此聲明的更新時，會在此聲明的頂部修改更新日期。對於重要更新，我們還會通過電子郵件、電話、推送通知或其他方式告知經銷商員工。我們鼓勵經銷商員工隨時查閱本聲明，瞭解我們如何是保護經銷商員工的個人信息的。

7.如何聯繫我們
如果我們的經銷商員工對本聲明有任何問題或疑慮，或是想對我們可能違反相關法律法規的行為進行投訴，請聯繫賓利經銷商培訓學院010 6531 3249或者發送電子郵件到bentleyacademy@bentleymotorschina.com。
大眾汽車（中國）銷售有限公司


`
